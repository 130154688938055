import React from "react"
import ReactHtmlParser from 'react-html-parser'

import { graphql, Link } from "gatsby"

import Layout from "../parts/layout"
import Seo from "../parts/seo"

const PublicationsPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Publications" />
      <div className="row">
        <h1 className="page-title">Publications</h1>

        <div className="items">
          { data.strapiDepsPage.items.map(item => {
            return (
              <div key={item.title} className="item box">
                <div className="text">
                  <div className="meta">{ item.header }</div>
                  <h2><Link to={`${item.link}`}>{ item.title }</Link></h2>
                  { ReactHtmlParser(item.excerpt) }
                </div>
                <div className="buttons">
                  { item.link && <Link className="button" to={`${item.link}`}>{ item.linkLabel ? item.linkLabel : "Read more..." }</Link> }
                  { item.file && <a className="button" href={`${item.file.localFile.publicURL}`}>{ item.linkLabel ? item.linkLabel : "Read more..." }</a> }
                </div>
              </div>
            )
          }) }
        </div>
      </div>
    </Layout>
  )
}

export default PublicationsPage

export const pageQuery = graphql`
  query PublicationQuery {
    strapiDepsPage {
      subtitle
      introduction
      items {
        header
        title
        excerpt
        link
        linkLabel
        file {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`